import React, { useEffect, useState } from 'react';
import {useStore} from 'react-context-hook';

const LabelGrid = () => {
    const [attrTypeObj, setAttrType] =  useStore('attrType',  null)
    const [uploadedImageURL, setUploadedImageURL] = useStore('uploadedImageURL', null)
    const [labels_present, setLabels_present] = useStore('labels_present',false);

    const [firstLoaded, setFirstLoaded] = useStore('loaded', false)

    // useEffect(()=>{
    //     console.log(attrTypeObj)
    // }, [attrTypeObj])
  
    // useEffect(()=>{
    //     if(responseResult.length > 0 && firstLoaded){
    //         setTimeout(() => {
    //             createBoundingBoxes()
    //         }, 50)
    //         const element = document.getElementById('result')
    //         element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    //     }
    // }, [ responseResult, categoryIndex, reset, firstLoaded])
   

    return (
        <div id='result' className=" container  grid-section">
            { uploadedImageURL && attrTypeObj && labels_present ?
                <div className="ui grid">
                    <div className="wide column img">
                        <div className="uploaded-image" id="uploaded-image">
                            <img src={uploadedImageURL} alt="image-AT" className="gridImage" id="image-upload" onLoad={()=>setTimeout(()=>setFirstLoaded(true), 50)} />
                        </div>
                    </div>
                    <div className="wide column attr-ld">
                        <div className="direction-column  at-segment">
                            <div className='attr-no-blur'>
                            {/* show attr  */}
                            { attrTypeObj ? attrTypeObj.map((attrType, index) => {
                                
                                    return (
                                        <div key={index} className={ 'direction-row padding '} style={{paddingLeft:' 3.5rem'}}>
                                            {Object.values(attrType).map((attrType, index) => {
                                               return (!Array.isArray(attrType) ? 
                                                        
                                                    <div className="attributeTabLeft" key={index}>
                                                        <p className="parahraph-attrType attr">{attrType.replace(/_/g, " ")}</p>
                                                    </div>
                                                : 
                                                    <div className="attributeTabLeft" key={index}>
                                                        {attrType.length>0 ? attrType.map((item, index) => {
                                                            
                                                            return ( 
                                                                <div className="ld attr" key={index}> 
                                                                    <p style={{textTransform:'capitalize'}}  className={ 'paragraph-tag border-left paragraph-tag'}> {item.attribute_name}
                                                                    {item.metadata.length>0 ?item.metadata.map((metaValue,i)=>{
                                                                        
                                                                        return <span key={i}>({metaValue.meta_value}) 
                                                                        <span className={attrType.length-1 <= index ? 'hideComa' : 'coma'}>, </span></span>
                                                                    }):""}
                                                                    </p> 
                                                                </div> 
                                                                ) 

                                                            })
                                                        : <div className="ld attr" > 
                                                                <p   className={'paragraph-tag border-left paragraph-tag'}>No detected tags</p> 
                                                            </div> 
                                                        }
                                                    </div>)
                                            })
                                        }
                                        </div>
                                    )
                               
                            })
                                :null
                            }
                            </div>
                        
                        </div>
                    </div>
                </div>
                
                :null 
                // <div className='noLabelTags'>
                //     <p>To see result upload image</p>
                // </div>
            }
            
        </div>
    )
}
export default LabelGrid