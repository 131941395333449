import React from 'react';
import UploadImg from '../Upload/UploadImg'
import LabelGrid from '../LabelGrid/LabelGrid'
const Home = () => {
   
    return (
        <div className='home'>
            <UploadImg/>
            <LabelGrid/>
        </div>
    )
}

export default Home
