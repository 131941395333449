import React, {useState, useEffect} from 'react'
import {withStore, useStore} from 'react-context-hook'

import uploadIcon from '../../assets/uploadIcon.png'
// import {getProduct} from '../../endpoints/getProduct'
import {postImage} from '../../endpoints/searchImage'
import UploadingProgress from './UploadingProgress'
import Completed from './Completed'
import ErrorModal from '../Modal/ErrorModal';
import DragAndDrop from './DragAndDrop'
// import LimitExceededModal from '../Modal/LimitExceededModal'
const UploadImg =()=>{
   
    const [attrType, setAttrType] =  useStore('attrType', {})
    const [ , setUploadedImageURL] = useStore('uploadedImageURL',null)
    const [textField, setTextField] = useState('')
    const [labels_present, setLabels_present] = useStore('labels_present',false);

    const [errorMsg, setErrorMsg] = useState(null)
    const [descriptionMsg, setDescriptionMsg] = useState(null)
    const [errImg, setErrImg] = useState(null)
    const [limitExceeded, setLimitExceeded] = useState(false)

    const [uploading, setUploading] = useState(false)
    const [complete, setComplete] = useState(false)
    const [dragging, setDragging] = useStore('dragging',false);

    // const [firstLoaded, setFirstLoaded] = useStore('loaded', false)

    // let history = useHistory();
    const closeResModal=()=>{
        setLimitExceeded(null);
        setErrorMsg(null);
        setUploading(false)
    }
    // upload Image form url
    const onChange = (e) => setTextField(e.target.value);
    const onSearch = async (e) => {
        e.preventDefault();
        setComplete(false)
        if(textField !== '') {
            setUploading(true)
            setTextField("")
            let res = await postImage(textField)
           
            if(res && res!==null && res !== undefined && 
                res.responseStatus !== 200) {
                setUploading(false)
                setComplete(false)
                if(res.responseStatus === 204)
                    setErrorMsg(res.message)
                else if(res.responseStatus === 429){
                    setLimitExceeded(res.message)
                }else if(res.responseStatus === 422){
                        setErrImg('imageFormat')
                        setErrorMsg('Unsupported image format ')
                        setDescriptionMsg('Please try again by uploading one of our supported image formats - JPG, PNG or TIFF')
                }
                else if(res.msg)
                    setErrorMsg(res.msg)
              
                else{
                    setErrImg('timeOut')
                    setErrorMsg('Timeout Error')
                    setDescriptionMsg('Server timed out, please check your internet connection and try again. If the problem doesn’t resolve contact us here.')
                }
                   
            }
            if(res.responseStatus==200 ){
                // setFirstLoaded(false)
                // console.log(res.responseJson.result)
                setTimeout(() => {
                    if(res.responseJson.errors.length>0){
                        setErrorMsg(res.responseJson.errors[0].error_message)
                    }
                    if(res.responseJson.result.detected_attribute_types.length>0){
                        setAttrType(res.responseJson.result.detected_attribute_types)   
                        setUploadedImageURL(textField) 
                        setLabels_present(res.responseJson.result.labels_present)   
                        setUploading(false)
                        setComplete(true)
                        
                    }
                    else  { 
                        setErrImg('noTags')
                        setErrorMsg("No tags detected")
                        setDescriptionMsg(
                            'Pixyle’s AI was unable to detect any fashion garment on the image that you uploaded. Please try using another image.'
                        )
                    }
                }, 1200); 
            }
        } 
    }
    
    const uploadFunc =  async (file) => {
        setComplete(false)
        if(file ) {
            setUploading(true)
            let res = await postImage(file)
        if(res && res!==null && res !== undefined && 
            res.responseStatus !== 200) {
            setUploading(false)
            setComplete(false)
            if(res.responseStatus === 204)
                setErrorMsg(res.message)
            else if(res.responseStatus === 429){
                setLimitExceeded(res.message)
            }else if(res.responseStatus === 422){
                    setErrImg('imageFormat')
                    setErrorMsg('Unsupported image format ')
                    setDescriptionMsg('Please try again by uploading one of our supported image formats - JPG, PNG or TIFF')
            }
            else if(res.msg)
                setErrorMsg(res.msg)
          
            else{
                setErrImg('timeOut')
                setErrorMsg('Timeout Error')
                setDescriptionMsg('Server timed out, please check your internet connection and try again. If the problem doesn’t resolve contact us here.')
            }
               
        }
        if(res.responseStatus==200 ){
            // setFirstLoaded(false)
          
            setTimeout(() => {
                if(res.responseJson.errors.length>0){
                    setErrorMsg(res.responseJson.errors[0].error_message)
                }
                if(res.responseJson.result.detected_attribute_types.length>0){
                    setAttrType(res.responseJson.result.detected_attribute_types)   
                    setUploadedImageURL(URL.createObjectURL(file))
                    setLabels_present(res.responseJson.result.labels_present)   
                    setUploading(false)
                    setComplete(true)
                    
                }
                else  { 
                    setErrImg('noTags')
                    setErrorMsg("No tags detected")
                    setDescriptionMsg(
                        'Pixyle’s AI was unable to detect any fashion garment on the image that you uploaded. Please try using another image.'
                    )
                }
            }, 1200); 
        }
        }
    }
    
    useEffect(()=>{
        if(complete){
            setTimeout(()=>{
                setComplete(false)
               
            },800)
        }
    },[complete])
    const handleUpload = async (e) =>{
        e.preventDefault()
         let file = e.target.files[0]
         uploadFunc(file)
    }
    const handleDrop=(e)=>{
        let file = e[0];
        uploadFunc(file)
    }

    return (
        <DragAndDrop handleDrop={(e)=>handleDrop(e)}>
        <div  accept=".jpg, .jpeg, .png, .jfif" id={`demoImg-at`}  
            className={dragging ? `upload  dropArea drop` : `dropArea upload `} >
            <div className={ uploading || complete? ' hide':dragging? 'show draggingImg' :'show' }>
               
                <div className={ uploading? 'uploadContainer hide' :'uploadContainer' }>
                    {/* <div className="columnUploadedImageButton btnDragnDrop">Upload an Image</div> */}
                    <label htmlFor  ="file" className="columnUploadedImageButton ">
                        <input type="file" name="file" id="file" accept=".jpg, .jpeg, .png, .jfif" 
                                className="inputfile" 
                                value=""
                                onChange={handleUpload}/> 
                                <img className='upload-icon' src={uploadIcon} alt='uploadIcon' /> Upload an Image  
                        {/* <span class="icon"><i class="upload icon"></i></span> */}
                    </label>
                    <div className="columnUploadedImageText">or</div>
                    <form onSubmit={onSearch} className={`columnUploadedImageInput` }>
                        <input type="text" id="name" name="name" placeholder="paste URL" className="inputLink" 
                            value={textField}
                            onChange={onChange}
                            />
                            <button className={`urlButtonAutomatic`}  onClick={onSearch}>Search</button>
                    </form>    
                </div>
                
                {/* <div className={`privacy-policy home `}>
                    By uploading an image you’re agreeing to <a  href="https://www.pixyle.ai/privacy" className='ppHome' >Pixyle’s Privacy Policy.</a>   
                </div>   */}
            </div>
            {/* {dragging? <div className='draggingImgTxt'> Drop an image here <div className='dropLightTxt'>(1 at a time)</div></div> : null} */}
            {uploading? < UploadingProgress  />:null } 
            {complete ? <Completed/> : null}
            {errorMsg ? <ErrorModal img={errImg} descriptionMsg={descriptionMsg} msg={errorMsg} exitFunc={closeResModal} /> : null}
        </div>
        </DragAndDrop>
    )
}
export default  UploadImg;