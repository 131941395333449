import React, {useEffect, useState}from 'react';

// import {withStore, useStore } from 'react-context-hook';
import Logo from '../../assets/Logo-DemoPixyle-SVG.svg'
// import { useLocation } from 'react-router';

const Navbar =() => {
    // const [openCustomDemoForm, setOpenCustomDemoForm] = useStore('book demo',false)

    return (
        <div className="container">
            <nav className="navbar">
                    <div className="nav-item-logo-img">
                        <a href="https://demo.pixyle.ai/">
                            <img src={Logo} alt="pix-logo"  className="ui small image logo-img"/>
                        </a>
                    </div> 
            </nav>
        </div>
      
    )
}

export default Navbar
