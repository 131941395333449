import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import Home from "./components/Home/Home";
import { withStore } from 'react-context-hook';

import "./sass/main.scss";
import './App.css';

function App() {
  return (
    <>
      <Navbar  />
      <Home/>
      <Footer />
    </>
  );
}

export default withStore(App);
